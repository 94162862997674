/**
 * project: Pimcore - Salescode
 * Created by EBiermann on 11.11.2019.
 */

"use strict";

import delegate from "es6-delegate/delegate";

import * as Utils from "../../utilities/Utilities";
import BaseClass from "../../classes/BaseClass";
import Init from "../../classes/Init";
import ButtonSwitch from "../Button/ButtonSwitch";


class Jobs extends BaseClass.inherit(Init) {
	constructor(element, referenceElement, app) {
		super(element, referenceElement, app);

		this._filterSwitchElements = this._element.querySelectorAll('[data-filter-by]');
		this._filterSwitches = {};
		this._filter = {};

		this._urlParams = new URLSearchParams(window.location.search);

		this._init();

	}

	_init() {
		if (this._filterSwitchElements) {
			this._processParams();
			this._filterSwitchElements.forEach((item, idx) => {
				const key = item.dataset.filterBy;
				this._filterSwitches[key] = new ButtonSwitch(item, 'button--black');
				this._filterSwitches[key].optionCallback = this._onSelectFilterOption.bind(this);
				if (this._filter[key]) {
					this._filterSwitches[key].selectOption(this._filter[key]);
				}

				delegate('click', item, this._onClickFilter.bind(this));
			});
		}

		this._attachEventsAndListeners();

	}

	_processParams() {
		if (this._urlParams.has('country')) {
			this._filter['country'] = this._urlParams.get('country');
		}

		if (this._urlParams.has('career-level')) {
			this._filter['career-level'] = this._urlParams.get('career-level');
		}

	}

	_attachEventsAndListeners() {
		delegate(this._element, 'click', '[data-filter-clear]', this._resetFilters.bind(this));
	}

	_applyFilters() {
		let queryParams = '';
		//Object.keys(this._filter).forEach((key) => {
		//	if (queryParams != "") {
		//		queryParams += "&";
		//	}
		//	queryParams += key + "=" + encodeURIComponent(this._filter[key]);
		//});
		//
		//console.log(this._filter, queryParams);

		//queryParams = 'filter='+encodeURIComponent(JSON.stringify(this._filter));

		//console.log(`http://google.com?${paramString.toString()}`);

		const paramString = new URLSearchParams(this._filter);
		const location = window.location;
		const url = [location.protocol, '//', location.host, location.pathname].join('');
		document.location.href = url + '?' + paramString + '#job-search';
	}

	_clearFilter(key) {
		delete this._filter[key];
	}

	_resetFilters(evt, element) {
		if (evt) evt.preventDefault();

		if (element) {

			if (element.dataset.filterClear != 'all') {
				this._clearFilter(element.dataset.filterClear);
			}
			else {
				this._filter = {};
			}
			//const url = [location.protocol, '//', location.host, location.pathname].join('');
			//document.location.href = url + '#job-search'
		}
		this._applyFilters();
	}

	_onClickFilter(evt, element) {
		if (evt) evt.preventDefault();
		Object.keys(this._filterSwitches).forEach((key) => {
			if ( this._filterSwitches[key].isActive) {
				this._filterSwitches[key].toggle();
			}
		});

		this._filterSwitches[element.dataset.filterBy].toggle();


	}

	_onSelectFilterOption(element) {
		console.log("on select option", element, this);

		const categoryElement = element.closest('[data-filter-by]');
		if (categoryElement) {
			const category = categoryElement.dataset.filterBy;
			console.log(category)
			this._filter[category] = element.dataset.option;
		}

		this._applyFilters();
	}
}

export default Jobs;