/**
 * project: Pimcore - Salescode
 * Created by EBiermann on 29.10.2019.
 */

"use strict";

import OverlayVideo from "./OverlayVideo";

class OverlayFactory {
	constructor(type, props, app) {
		switch(type) {
			case 'video':
				return new OverlayVideo(props, app);
				break;

		}
	}
}

export default OverlayFactory