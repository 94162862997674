/**
 * project: Pimcore - Salescode
 * Created by EBiermann on 22.10.2019.
 */


"use strict";

import * as Hammer from "hammerjs/hammer";
import {TimelineMax, TweenMax} from "gsap/all";

import delegate from "es6-delegate/delegate";

import Events from "../../salescode.events";
import * as Utils from "../../utilities/Utilities";
import BaseClass from "../../classes/BaseClass";
import Init from "../../classes/Init";

import IntroSliderItem from "./IntroSliderItem";
//import PreloadBase from "../../classes/preloader/PreloadBase";

class IntroSlider extends BaseClass.inherit(Init) {
	constructor(element, referenceElement, app) {
		super(element, referenceElement, app, true);

		this._items = [];
		this._activeItem = null;
		this._activeItemId = 0;

		this._needSlider = false;
		this._hammer = null;

		this._initialCSSDistanceToLeft = Utils.Helper.getCssVariableValue(this._element, '--element-distance-to-left');
		this._initialCSSElementWidth = Utils.Helper.getCssVariableValue(this._element, '--element-width');


		this._timelineEnter = null;
		this._timelineLeave = null;

		this._tweenInProgress = false;
		this._tweenContentEase = null;
		this._tweenContentX = 0;
		this._tweenContentScale = 1;
		this._tweenMediaEase = null;
		this._tweenMediaX = 0;
		this._tweenMediaScale = 1;

		this._init();

	}

	//get

	//public

	//private
	_init() {
		let itemInstance;

		const sliderItems = this._element.querySelectorAll('.intro-slider__item');
		if (sliderItems.length > 0) {
			sliderItems.forEach((item, idx) => {
				itemInstance = new IntroSliderItem(item, this._referenceElement, this._app);
				if (idx == 0) {
					itemInstance.active = true;
					this._activeItem = itemInstance;
				}

				this._items.push(itemInstance);
			});

			this._needSlider = this._items.length > 1 ? true : false;
		}

		if (this._needSlider) {
			this._hammer = new Hammer(this._element);
		}
		else {
			this._element.dataset.disabled = 'slider';
		}

		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		let multi;

		window.addEventListener(Events.layout.resize.did, this._onResize.bind(this));
		window.addEventListener(Events.layout.mutation.oberserved, this._onMutationObserved.bind(this));

		if (this._needSlider) {
			delegate('mouseenter', '.intro-slider__hover', this._onMouseEnterControlArea.bind(this));
			delegate('mouseleave', '.intro-slider__hover', this._onMouseLeaveControlArea.bind(this));
			delegate('click', '.intro-slider__hover', this._onClickControlArea.bind(this));

			this._hammer.on('swipeleft swiperight', this._onClickControlArea.bind(this));
		}

		this._app.handlers.resize.attach(this._element, (width, height) => {
			//console.log("observed resize ", width, height);
			multi = (width / 19.2) / 100;
			multi = multi >= 0.6 ? multi : 0.6;

			if (device == 'phone') multi = 1;

			this._items.forEach((item, idx) => {
				item.fontSizeMulti = multi;
			});
		});
	}

	_setDimensions() {
		TweenMax.set(this._element, {
			'--element-height': Utils.Helper.getHeight(this._element, true) + 'px'
		})
	}

	_onMutationObserved(evt) {
		this._setDimensions();
	}

	_onResize(evt) {
		this._setDimensions();
	}

	_onMouseEnterControlArea(evt, targetElement) {
		const direction = targetElement.dataset.direction;
		if (this._tweenInProgress) return;

		if (typeof direction === 'string') {
			const xPercentValueContent = direction == 'previous' ? 8 : -5;
			const xPercentValueMedia = direction == 'previous' ? 5 : -5;

			this._tweenContentEase = Elastic.easeOut.config(1.5, 0.75);
			this._tweenMediaEase = this._tweenContentEase;

			if (this._timelineLeave) this._timelineLeave.kill();

			this._timelineEnter = new TimelineMax({
				onUpdate: () => {
					//console.log(this._activeItem.content._gsTransform);
					this._tweenContentX = this._activeItem.content._gsTransform.xPercent;
					//this._tweenMediaX = this._activeItem.media._gsTransform.xPercent;

					this._tweenContentScale = this._activeItem.content._gsTransform.scaleX;
					//this._tweenMediaScale = this._activeItem.content._gsTransform.scaleX;
				}
			});
			this._timelineEnter
				.to(this._activeItem.content, 1.4, {
					scale: 1.12,
					xPercent: xPercentValueContent,
					ease: this._tweenContentEase
				})
				.to(this._activeItem.media, 1.4, {
					scale: 1.12,
					xPercent: xPercentValueMedia / 1.5,
					ease: this._tweenMediaEase
				}, 0);


		}
	}

	_onMouseLeaveControlArea(evt, targetElement) {
		const direction = targetElement.dataset.direction;
		if (this._tweenInProgress) return;

		if (typeof direction === 'string') {
			if (this._timelineEnter) this._timelineEnter.kill();

			this._timelineLeave = new TimelineMax({
				onUpdate: () => {
					this._tweenContentX = this._activeItem.content._gsTransform.xPercent;
					//this._tweenMediaX = this._activeItem.media._gsTransform.xPercent;

					this._tweenContentScale = this._activeItem.content._gsTransform.scaleX;
					//this._tweenMediaScale = this._activeItem.media._gsTransform.scaleX;
				}
			});
			this._timelineLeave
				.to(this._activeItem.content, 0.6, {
					scale: 1,
					xPercent: 0,
					ease: Quad.easeOut
				}).to(this._activeItem.media, 0.6, {
					scale: 1,
					xPercent: 0,
					ease: Quad.easeOut
				}, 0);
		}
	}

	_onClickControlArea(evt, targetElement) {
		let direction;

		if (targetElement) {
			direction = targetElement.dataset.direction;
		}
		else {
			if (evt.type == 'swipeleft') {
				direction = 'next';
			}
			else if (evt.type == 'swiperight') {
				direction = 'previous';
			}
		}

		return new Promise((resolve, reject) => {
			if (this._tweenInProgress) {
				reject('tween in progress');
				return;
			}

			if (!direction || direction === undefined) {
				reject('direction is not defined');
				return;
			}

			if (this._timelineEnter) this._timelineEnter.kill();
			if (this._timelineLeave) this._timelineLeave.kill();


			if (typeof direction === 'string') {
				switch(direction) {
					case 'previous':
						this._activeItemId--;
						if (this._activeItemId < 0) {
							this._activeItemId = this._items.length - 1;
						}
						break;
					case 'next':
						this._activeItemId++;
						if (this._activeItemId > this._items.length - 1) {
							this._activeItemId = 0;
						}
						break;
				}

				const nextItem = this._items[this._activeItemId];
				const timeline = new TimelineMax({
					onStart: () => {
						this._tweenInProgress = true;
					},
					onComplete: () => {
						this._activeItem.active = false;
						this._activeItem = nextItem;
						this._activeItem.active = true;
						this._tweenInProgress = false;

						this._tweenContentX = 0;
						this._tweenContentScale = 1;

						resolve(this._activeItem);
					}
				})


				const distanceToLeft = direction == 'previous' ? '100%' : parseFloat(this._initialCSSDistanceToLeft) - parseFloat(this._initialCSSElementWidth) + '%';
				const xPercentValue = direction == 'previous' ? '+=10' : '-=2';

				timeline.timeScale(1.8);
				timeline
					.set(nextItem.element, { zIndex: 5 })
					.to(this._activeItem.content, 0.3, {
						opacity: 0,
						scale: 1.3,
						xPercent: xPercentValue,
						ease: Quad.easeOut
					})
					.fromTo(this._activeItem.element, 0.6, {
						'--element-distance-to-left': this._initialCSSDistanceToLeft
					}, {
						'--element-distance-to-left': distanceToLeft,
						ease: Quad.easeInOut
					}, 0.2)
					.from(nextItem.content, 1.8, {
						opacity: 0,
						xPercent: this._tweenContentX,
						scale: this._tweenContentScale,
						ease: this._tweenContentEase
					}, 0.6)
					.set(this._activeItem.element, { clearProps: "all" })
					.set(this._activeItem.content, { clearProps: "all" })
					.set(this._activeItem.media, { clearProps: "all" })
					.set(nextItem.element, { clearProps: "all" });
			}
			else {
				reject('no slide direction set');
			}
		})
	}
}

export default IntroSlider;