/**
 * project: Pimcore - Salescode
 * Created by EBiermann on 11.10.2019.
 */
"use strict";

import * as Utils from "../../utilities/Utilities";

class TabItem {
	constructor(element) {
		this._element = element;
		this._container = this._element.querySelector('.grid__container');
		this._height = 0;
		this._isActive = false;
		this._init();
	}

	//public

	get element() {
		return this._element;
	}

	get height() {
		this._height = Utils.Helper.getHeight(this._container, true);
		return this._height;
	}

	get isActive() {
		return this._isActive;
	}

	set isActive(isActive) {
		this._isActive = isActive
	}

	//private
	_init() {
		this._height = Utils.Helper.getHeight(this._container, true);
	}
}

export default TabItem;