/**
 * project: Pimcore - Salescode
 * Created by EBiermann on 12.12.2019.
 */

"use strict";

import {gsap} from "gsap/all";
import Events from "../../salescode.events";

class Number {
	constructor(element, referenceElement, app) {

		this._element = element;
		this._referenceElement = referenceElement;
		this._app = app;

		this.shouldAnimate = false;
		this.animateComplete = false;
		this.valueElement = this._element.querySelector('.numbers__value-value');
		this.originValue = null;
		this.targetValue = null;

		this.labelElement = this._element.querySelector('.numbers__label');

		this._init();
	}

	_init() {

		console.log(this._element, "animate");

		this.shouldAnimate = this._element.classList.contains('numbers--count-up');
		this.originValue = parseInt(this.valueElement.innerText);
		if (this.shouldAnimate) {
			this._app.handlers.intersection.attach(this._element);
			this.targetValue = parseInt(this.valueElement.dataset.targetValue);
		}

		console.log("init number", this);

		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		this._element.addEventListener(Events.inview.in, this._onInViewIn.bind(this));
	}

	_onInViewIn(evt) {
		if (this.animateComplete) return;

		let valueHolder = {
			value: this.originValue
		}

		gsap.to(valueHolder, {
			duration: 1.2,
			value: this.targetValue,
			snap: {
				value: 1
			},
			ease: 'quad.inOut',
			onUpdate: () => {
				//console.log(valueHolder);
				this.valueElement.innerHTML = valueHolder.value
			},
			onComplete: () => {
				this.animateComplete = true;
			}
		});
	}
}

export default Number;