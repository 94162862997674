/**
 * project: Pimcore - Salescode
 * Created by EBiermann on 20.11.2019.
 */

import { gsap } from "gsap/all";

import Swup from 'swup';
import SwupBodyClassPlugin from '@swup/body-class-plugin';
import SwupJsPlugin from '@swup/js-plugin';
import SwupDebugPlugin from '@swup/debug-plugin';

import Events from "../salescode.events";
import * as Utils from "../utilities/Utilities";

class PageTransitionController {
	constructor(app) {
		this.app = app;
		this.swup = null;
		this.curtain = document.querySelector('.xhr-curtain');

		this._init();
	}

	//public
	getInstance() {
		return this;
	}

	//private
	_init() {
		console.log("page transition controller", "device:",device, "devmode:", devmode, "editmode", editmode, this.curtain);

		const durationIn = device != 'phone' ? 0.50 : 0.4;
		const durationOut = 0.35;
		const staggerTime = 0.12;
		const colors = [
			'rgba(45,44,45,1)',
			'rgba(54,18,54,1)',
			'rgba(36,74,76, 1)',
			'rgba(126,176,158,1)',
			//'rgba(222,232,142,1)',
			//'rgba(252,99,107,1)'
		];

		const swupAnimationOptions = [
			{
				from: '(.*)',
				to: '(.*)',
				in: (next) => {
					let tlIn = gsap.timeline({
						ease: 'expo.inOut',
						onComplete: () => {
							next();
							window.scrollTo({
								top: 0,
								left: 0,
								behavior: 'smooth'
							});

						}
					});

					tlIn
						.fromTo(document.querySelectorAll('.xhr-curtain__basic'), {
							//y: '-100%',
							//height: '100%',
							opacity: 1
						}, {
							duration: durationIn,
							y: '-200%',
							//height: '0%',
							opacity: 1
						})
						.set(this.curtain, { display: 'none'})
						.set(document.querySelectorAll('.xhr-curtain__basic'), { x: 0, y: 0});

					tlIn.pause(0);

					const introSlider = document.querySelector('.intro-slider');
					if (introSlider) {
						imagesLoaded(introSlider, {background: true}, () => {
							setTimeout(() => {
								tlIn.play();
							},100)
						});
					}
					else {
						tlIn.play();
					}
				},
				out: (next) => {

					let tlOut = gsap.timeline({
						ease: 'expo.inOut',
						onComplete: () => {
							next();
						}
					});

					tlOut
						.set(this.curtain, { display: 'block '})
						.fromTo(document.querySelectorAll('.xhr-curtain__basic'), {
							y: '0%',
							height: '50%',
							opacity: 1
						}, {
							duration: durationOut,
							y: '-100%',
							height: '100%',
							opacity: 1
						})
				}
			}
		];

		const swupOptions = {
			cache: false,
			plugins: [
				new SwupBodyClassPlugin(),
				new SwupJsPlugin(swupAnimationOptions),
				//new SwupDebugPlugin()
			],
			containers: [
				'.xhr-container',
				'.navigation--primary',
				'.menu',
				'.languages--header',
				'.header__logo'

			],
			requestHeaders: {
				'X-Requested-With': 'xhr-slc',
			}
		}

		this.swup = new Swup(swupOptions);
		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		if (this.swup) {
			this.swup.on('contentReplaced', this._onContentReplaced.bind(this));
			this.swup.on('samePageWithHash', this._onSamePageWithHash.bind(this));
		}
	}

	_onContentReplaced(evt) {
		this.app.components.lazyload.update();
		Utils.DispatchEvent.dispatch(Events.preload.xhr, {});
	}

	_onSamePageWithHash(evt) {
		Utils.Helper.scrollToHash(evt.delegateTarget);
	}
}

export default PageTransitionController;