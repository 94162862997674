/**
 * project: Pimcore - Salescode
 * Created by EBiermann on 17.10.2019.
 */


"use strict";

import {gsap, MorphSVGPlugin} from "gsap/all";
gsap.registerPlugin(MorphSVGPlugin);

import Events from "../salescode.events";
import * as Utils from "../utilities/Utilities";
import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";

class Hamburger extends BaseClass.inherit(Init) {
	constructor(element) {
		super(element);

		this._svg = this._element.querySelector('svg');
		this._hamburger = this._svg.querySelector('#hamburger');
		this._cross = this._svg.querySelector('#cross');

		this._open = false;
		this._tl = null;

		//should be done by config / gsap2
		//let forceImport = new MorphSVGPlugin();

		this._init();

	}

	//public

	//private
	_init() {

		//this._hamburger = MorphSVGPlugin.convertToPath(this._hamburger);
		//this._cross = MorphSVGPlugin.convertToPath(this._cross);


		this._tl = gsap.timeline({
			onComplete: () => {
				this._open = true;
			},
			onReverseComplete: () => {
				this._open = false;
			},
			paused: true
		});

		this._tl
			.to(this._svg.querySelector('#hamburger-top'), {
				duration: 0.15,
				morphSVG: {
					shape: '#cross-bottom',
					shapeIndex: 4
				},
				ease: 'quad.inOut'
			}, 0)
			.to(this._svg, {
				duration: 0.1,
				scale: 0.8,
			}, 0)
			.to(this._svg.querySelector('#hamburger-bottom'), {
				duration: 0.15,
				morphSVG: {
					shape: '#cross-top',
					shapeIndex: 9
				},
				ease: 'quad.inOut'
			}, 0);

		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		window.addEventListener(Events.preload.xhr, this._onPreloadXHR.bind(this));

		this._element.addEventListener('click', (e) => {

			if (this._open) {
				Utils.DispatchEvent.dispatch(Events.navigation.do.hide, {
					action: () => this._tl.reverse()
				});

			}
			else {
				Utils.DispatchEvent.dispatch(Events.navigation.do.show, {
					action: () => this._tl.play()
				});
			}
		})
	}

	_onPreloadXHR(evt) {
		if (this._open) {
			if (this._tl) {
				this._tl.reverse();
			}
		}
	}

}

export default Hamburger;