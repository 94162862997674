/**
 * project: Pimcore - Salescode
 * Created by EBiermann on 14.10.2019.
 */


"use strict";

import BaseClass from "../../classes/BaseClass";
import Init from "../../classes/Init";

import AccordionItem from "./AccordionItem";

class Accordion extends BaseClass.inherit(Init) {
	constructor(element) {
		super(element);

		this._items = [];
		this._itemElements = this._element.querySelectorAll('.accordion__item');

		this._init();

	}
	//public

	//private
	_init() {
		this._itemElements.forEach((item, idx) => {
			this._items.push(new AccordionItem(item));
		})
	}
}

export default Accordion;