/**
 * project: Pimcore - Salescode
 * Created by EBiermann on 14.10.2019.
 */


"use strict";

import Events from "../salescode.events";
import * as Utils from "../utilities/Utilities";
import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";

class Triangle extends BaseClass.inherit(Init) {
	constructor(element, referenceElement, app) {
		super(element, referenceElement, app);

		this._listeningElements = [];
		this._dimensionHelper = this._element.querySelector('.grid__column');
		this._init();

	}

	//public

	//private
	_init() {
		this._attachEventsAndListeners();
		this._collectListeningElements();
	}

	_attachEventsAndListeners() {
		window.addEventListener(Events.layout.resize.did, this._onResize.bind(this));
		window.addEventListener(Events.layout.mutation.oberserved, this._onLayoutMutationObserved.bind(this));
		window.addEventListener(Events.preload.finished, this._onResize.bind(this));
	}

	_collectListeningElements() {
		const elements = document.querySelectorAll('[data-triangle-listener]:not([data-triangle-listener="listening"])');
		if (elements.length > 0) {
			elements.forEach((element, idx) => {
				console.log("add listening element", element);

				element.dataset.triangleListener = 'listening';
				this._listeningElements.push(element);
			})
		}
	}

	_onLayoutMutationObserved() {
		//this._collectListeningElements();
		this._collectListeningElements();
		this._onResize();
	}

	_onResize() {
		const dimension = Utils.Helper.getWidth(this._dimensionHelper, true);
		//this._element.style.setProperty('--layout-triangle-dimension', dimension + 'px');
		if (dimension && !Number.isNaN(dimension)) {
			this._app.lifecycle.elements.body.style.setProperty('--layout-triangle-dimension', dimension + 'px');
		}

		//if (this._listeningElements.length > 0) {
		//	this._listeningElements.forEach((listeningElement, idx) => {
		//
		//	})
		//}

		Utils.DispatchEvent.dispatch(Events.triangle.resize.did, {
			dimension: dimension
		});
	}

}

export default Triangle;