/**
 * project: Pimcore - Salescode
 * Created by EBiermann on 15.03.2020.
 */

"use strict";

import Events from "../salescode.events";
import * as Utils from "../utilities/Utilities";
import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";
import delegate from "es6-delegate/delegate";
import {gsap} from "gsap/all";

class Totop extends BaseClass.inherit(Init) {
	constructor(element, referenceElement, app) {
		super(element, referenceElement, app);

		this._lastScrollTop = 0;
		this._visible = false;
		this._animating = false;
		this._tl = gsap.timeline({
			onComplete: () => {
				this._animating = false;
			},
			onReverseComplete: () => {
				this._animating = false;
			},
			paused: true
		});

		this._tl
			.fromTo(this._element, {
				opacity: 0,
				yPercent: 100,
			}, {
				opacity: 0.8,
				yPercent: 0,
				ease: 'quad.out',
				duration: 0.25
			})

		this._init();


	}

	//public

	//private
	_init() {
		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		delegate(this._element, 'click', '.button', this._onClick.bind(this));
		window.addEventListener(Events.layout.scroll.did, this._onOptimiziedScroll.bind(this));
	}

	_onClick(evt) {
		if (evt) evt.preventDefault();
		window.scrollTo({
			top: 1,
			left: 0,
			behavior: 'smooth'
		});
	}

	_onOptimiziedScroll(evt) {
		let currentScrollTop = evt ? evt.detail.scrollTop : 0, vpH = Utils.Helper.getViewportHeight();

		if (!this._animating) {
			if (this._lastScrollTop < currentScrollTop && currentScrollTop > vpH && !this._visible) {
				//show
				this._element.classList.add('to-top--clickable');
				this._visible = true;
				this._animating = true;
				this._tl.play();

			} else if (this._lastScrollTop > currentScrollTop && currentScrollTop <= vpH && this._visible) {
				//hide
				this._element.classList.remove('to-top--clickable');
				this._visible = false;
				this._animating = true;
				this._tl.reverse();
			}
		}

		this._lastScrollTop = currentScrollTop;
	}

}

export default Totop;