/**
 * project: Pimcore - Salescode
 * Created by EBiermann on 16.10.2019.
 */


"use strict";


import TeaserBase from "./TeaserBase";
import Events from "../../salescode.events";

class TeaserJob extends TeaserBase {
	constructor(element) {
		super(element);

		this._contentCopy = this._element.querySelector('.teaser__content-copy');

		this._init();
	}

	// public

	// get / set

	// private
	_init() {
		if (this._content && !this._contentCopy) {
			const copyWrapper = document.createElement('div');
			const copyContent = this._content.cloneNode(true);
			copyContent.setAttribute('aria-hidden', 'true');

			copyWrapper.classList.add('teaser__content-copy');
			copyWrapper.appendChild(copyContent);
			this._contentCopy = copyWrapper;
			this._element.appendChild(copyWrapper);
		}

		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		window.addEventListener(Events.preload.begin, this._onPreloadBegin.bind(this));
		window.addEventListener(Events.layout.mutation.oberserved, this._onMutationObserved.bind(this));
		window.addEventListener(Events.layout.resize.did, this._onDidResize.bind(this));
		window.addEventListener(Events.layout.dimension.check, this._onDimensionCheck.bind(this));
	}

	_onPreloadBegin(evt) {
		super._onPreloadBegin(evt);
	}

	_onMutationObserved(evt) {
		super._onMutationObserved(evt);
	}

	_onDidResize(evt) {
		super._onDidResize(evt);
	}

	_onDimensionCheck(evt) {
		super._onDimensionCheck(evt);
	}
}

export default TeaserJob;