/**
 * project: Pimcore - Salescode
 * Created by EBiermann on 29.10.2019.
 */
"use strict";

import Selectr from "mobius1-selectr";

import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";

class Select extends BaseClass.inherit(Init) {
	constructor(element, referenceElement, app) {
		super(element, referenceElement, app);

		this._selectr = null;

		this._init();

	}

	_init() {
		let selectOptions;

		selectOptions = {
			searchable: false,
			customClass: 'dropdown-instance'
		};

		this._selectr = new Selectr(this._element, selectOptions);
	}
}

export default Select;