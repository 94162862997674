/**
 * project: Pimcore - Salescode
 * Created by EBiermann on 30.10.2019.
 */


"use strict";
import Dropzone from "dropzone";
import Swal from "sweetalert2";
import Pristine from "pristinejs/src/pristine";

class JobApplyForm {
	constructor(element, app) {
		this._element = element;
		this._formData = new FormData(this._element);;
		this._uploadElement = this._element.querySelector('.form__upload');
		this._validator = new Pristine(this._element, {
			classTo: 'form__group',
			errorClass: 'form__group--has-error',
			successClass: 'form__group--has-success',
			//errotTextParent: false
			errorTextParent: 'form__group',
			errorTextTag: 'div',
			errorTextClass: 'tooltip tooltip--half'
		});

		this._dz = null;
		this._init();
	}

	_init() {
		if (this._uploadElement) {
			const self = this;

			const previewNode = this._uploadElement.querySelector('.dz-preview-template');
			const previewTemplate = previewNode.innerHTML;
			previewNode.parentNode.removeChild(previewNode);

			this._dz = new Dropzone(this._element, {
				url: this._element.getAttribute('action'),
				autoProcessQueue: false,
				paramName: 'files',
				uploadMultiple: true,
				chunking: false,
				maxFiles: 10,
				maxFilesize: 10,
				parallelUploads: 10,
				addRemoveLinks: true,
				acceptedFiles: '.jpg,.jpeg,.png,.pdf,.doc,.docx',
				createImageThumbnails: false,
				clickable: this._uploadElement.querySelector('.form__upload-zone'),
				previewsContainer: this._uploadElement.querySelector('.form__upload-preview'),
				previewTemplate: previewTemplate,
				init: function() {
					this.on('sendingmultiple', (file, xhr, formData) => {
						// Gets triggered when the form is actually being sent.
						// Hide the success button or the complete form.
						this.element.classList.add('form--sending');
					})
					this.on('successmultiple', (files, response) => {
						const json = response;
						//var json = JSON.parse(response);
						if (json.target) {
							document.location.href = json.target;
						}

					});
					this.on('errormultiple',(files, response) => {;
						this.element.classList.remove('form--sending');

						for(var i = 0; i < files.length; i++) {
							if (files[i].status == 'error') {
								this.removeFile(files[i]);
							}
						}

						return Swal.fire({
							type: 'error',
							text: response,
							customClass: {
								confirmButton: 'button button--primary button--mint'
							}
						});
					});
				}
			})
		}

		this._attachEventsAndListeners()
	}

	_attachEventsAndListeners() {
		//this._element.addEventListener('submit', this._onSubmit.bind(this));
		this._element.querySelector('.job__apply-submit button').addEventListener('click', this._onSubmit.bind(this));
	}

	_onSubmit(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		if (this._validator.validate()) {
			if (this._dz) {
				if (this._dz.getQueuedFiles().length > 0) {
					this._dz.processQueue();
				} else {
					this._element.submit();
				}
			} else {
				this._element.submit();
			}
		}
		else {

			//console.log(this._validator.getErrors());
		}
	}
}

export default JobApplyForm;