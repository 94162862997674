/**
 * project: Pimcore - Salescode
 * Created by EBiermann on 01.10.2019.
 *
 * https://stackoverflow.com/questions/34655616/create-an-instance-of-a-class-in-es6-with-a-dynamic-name
 */
//class ComponentFactory {
//	constructor() {
//
//	}
//}
//
//export default ComponentFactory;

import ScrollIndicator from "../components/ScrollIndicator";
import Header from "../components/Header";
import Menu from "../components/Menu";
import Hamburger from "../components/Hamburger";
import Tabs from "../components/Tabs/Tabs";
import Triangle from "../components/Triangle";
import PageIntro from "../components/PageIntro";
import Accordion from "../components/Accordion/Accordion";
import TeaserBlog from "../components/Teaser/TeaserBlog";
import TeaserJob from "../components/Teaser/TeaserJob";
import TeaserDocument from "../components/Teaser/TeaserDocument";
import Video from "../components/Video";
import IntroSlider from "../components/IntroSlider/IntroSlider";
import Select from "../components/Select";
import Jobs from "../components/Job/Jobs";
import Job from "../components/Job/Job";
import SocialShare from "../components/SocialShare";
import Form from "../components/Form";
import Numbers from "../components/Numbers/Numbers";
import Totop from "../components/Totop";
import Blog from "../components/Blog/Blog"
import ScrollTo from '../components/ScrollTo';
import ExpandableList from '../components/ExpandableList';

const classes = {
	ScrollIndicator,
	Header,
	Menu,
	Hamburger,
	Tabs,
	Triangle,
	PageIntro,
	Accordion,
	TeaserBlog,
	TeaserJob,
	TeaserDocument,
	Video,
	IntroSlider,
	Select,
	Jobs,
	Job,
	SocialShare,
	Form,
	Numbers,
	Totop,
	Blog,
	ScrollTo,
	ExpandableList
}

export default function ComponentFactory(name) {
	//console.log("ComponentFactory", name, classes);
	return classes[name];
}