/**
 * project: Pimcore - Salescode
 * Created by EBiermann on 31.10.2019.
 */


"use strict";

import delegate from "es6-delegate/delegate";

import * as Utils from "../utilities/Utilities";
import Events from "../salescode.events";
import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";


class SocialShare extends BaseClass.inherit(Init) {
	constructor(element, referenceElement, app) {
		super(element, referenceElement, app);

		this._shareType = '';
		this._shareTitle = '';
		this._shareHref = '';
		this._shareImageSrc = '';

		this._urls = {
			facebook: 'https://facebook.com/sharer/sharer.php?u=__HREF__',
			twitter: 'https://twitter.com/share?url=__HREF__&amp;text=__TITLE__&amp;hashtags=Salescode',
			instagram: '',
			linkedin: 'https://www.linkedin.com/shareArticle?mini=true&url=__HREF__&title=__TITLE__&source=https%3A%2F%2Fsalescode.io',
			pinterest: 'http://pinterest.com/pin/create/button/?url=__HREF__&media=__IMAGESRC__&description=__TITLE__',
			mail: 'mailto:?subject=__TITLE__&amp;body=__HREF__',
			print: '',
		}

		this._init();
	}

	_init() {
		delegate('click', '.social-share__item', this._share.bind(this));
	}

	_share(evt, targetElement) {
		if (evt) {
			evt.preventDefault();
			evt.stopPropagation();
			if (targetElement) {
				this._shareType = targetElement.dataset.type;
				this._shareTitle = this._element.dataset.title;
				this._shareHref = this._element.dataset.href;
				this._shareImageSrc = this._element.dataset.imageSrc;

				this._openShareWindow(600, 400);
			}
		}
	}

	_openShareWindow(width, height) {
		const left = (screen.width / 2) - (width / 2), top = (screen.height / 2) - (height / 2);
		let url = this._urls[this._shareType.toLowerCase()];
		url = url.replace('__HREF__', encodeURI(this._shareHref.toString()));
		url = url.replace('__TITLE__', encodeURI(this._shareTitle.toString()));
		url = url.replace('__IMAGESRC__', encodeURI(this._shareImageSrc.toString()));

		if (self._shareType == 'mail') {
			doc.location.href = url;
		}
		else if (self._shareType == 'print') {
			document.print();
		}
		else {
			window.open(
				url,
				"",
				"menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=" + width + ",height=" + height + ",top=" + top + ",left=" + left
			);
		}
		Utils.DispatchEvent.dispatch(Events.socialshare.share);
	}
}

export default SocialShare;