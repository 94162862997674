/**
 * project: Pimcore - Salescode
 * Created by EBiermann on 29.10.2019.
 */


"use strict";

import delegate from "es6-delegate/delegate";
import OverlayFactory from "./OverlayFactory";


class OverlayController  {
	constructor(app) {
		this._init();

		this._overlay = null;

	}

	//public

	//private

	_init() {
		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		delegate('click', '[data-overlay]', this._open.bind(this));
		delegate('click', '[data-overlay-close]', this._close.bind(this));
	}

	_open(evt, targetElement) {
		const type = targetElement.dataset.overlay;
		const content = targetElement.dataset.overlayContent;

		try {
			if (this._overlay) throw new Error('overlay allready open');
			this._overlay = new OverlayFactory(type, content, app);
			this._overlay.open();
		}
		catch(e) {
			console.info(e);
		}
	}

	_close(evt, targetElement) {
		evt.preventDefault();

		try {
			if (!this._overlay) throw new Error('overlay not found');
			this._overlay.close().then(() => {
				this._overlay.destroy();
				this._overlay = null;
			});
		}
		catch(e) {
			console.info(e);
		}

	}
}

export default OverlayController;