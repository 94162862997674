/**
 * project: Pimcore - Salescode
 * Created by EBiermann on 15.03.2020.
 */

"use strict";

import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";
import delegate from "es6-delegate/delegate";

class ScrollTo extends BaseClass.inherit(Init) {
	constructor(element, referenceElement, app) {
		super(element, referenceElement, app);
		this._init();
	}

	//public

	//private
	_init() {
		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		delegate('click', this._element, this._onClick.bind(this));
	}

	_onClick(evt) {
		if (evt) evt.preventDefault();

		let targetSelector = this._element.getAttribute('href');
		let targetElement = document.querySelector(targetSelector);
		if (targetElement) {
			targetElement.scrollIntoView({
				block: 'start',
				behavior: 'smooth'
			});
		}


	}
}

export default ScrollTo;