/**
 * project: Pimcore - Salescode
 * Created by EBiermann on 22.10.2019.
 */


"use strict";

import BaseClass from "../../classes/BaseClass";
import Init from "../../classes/Init";

class IntroSliderItem extends BaseClass.inherit(Init) {


	constructor(element, referenceElement, app) {
		super(element, referenceElement, app);

		this._content = this._element.querySelector('.intro-slider__content');
		this._media = this._element.querySelector('.intro-slider__media');
		this._text = this._content.querySelector('.intro-slider__text');
		this._title = this._text.querySelector('.intro-slider__title');
		this._tagline = this._text.querySelector('.intro-slider__tagline');
		this._subline = this._text.querySelector('.intro-slider__subline');

		this._fontSizeMulti = 1;
		this._active = false;
		this._hasVideoBackground = false;
		this._video = false;

		this._init();

	}
	//public

	//get
	get fontSizeMulti() {
		return this._fontSizeMulti;
	}

	get text() {
		return this._text;
	}

	get content() {
		return this._content;
	}

	get title() {
		return this._title;
	}

	get tagline() {
		return this._tagline;
	}

	get subline() {
		return this._subline;
	}

	get media() {
		return this._media;
	}

	get active() {
		return this._active;
	}

	//set
	set fontSizeMulti(multi) {
		this._fontSizeMulti = multi;

		if (this._title) {
			//console.log("set font size multi on ", this._element);
			this._title.style.setProperty('--font-size-multi', multi);
		}
	}

	set active(active) {
		if (active == false) {
			this._element.classList.remove('intro-slider--active');
			if (this._video) {
				this._video.pause();
			}
		}
		else {
			this._element.classList.add('intro-slider--active');
			if (this._video && this._video.readyState === 4) {
				this.playVideo();
			}
		}
		this._active = active;
	}

	//private

	_init() {
		const video = this._media.querySelector('video');
		if (video) {
			this._hasVideoBackground = true;
			this._video = video;
		}

		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {

	}

	async playVideo() {
		try {
			await this._video.play();
		} catch(err) {
			console.error(err);
		}
	}
}

export default IntroSliderItem;