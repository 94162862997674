/**
 * project: Pimcore - Salescode
 * Created by EBiermann on 15.10.2019.
 */


"use strict";


import delegate from "es6-delegate/delegate";
import * as Utils from "../../utilities/Utilities";
import ButtonBase from "./ButtonBase";


class ButtonSwitch extends ButtonBase {
	constructor(element, activeClass) {
		super(element, activeClass);

		super._nonActiveClass = 'button--mint';
		super._activeClass = 'button--black';
		super._timelineElement = this._element.querySelector('.button__plus-minus');
		super._timelineTime = 0.2;
		super._timelineTo = {
			'--button-rotate-before': '0deg',
			'--button-rotate-after': '0deg',
			ease: 'quad.inOut'
		}

		this._options = this.element.querySelector('.button__options');
		this._optionCallback = null;
		this._optionActive = null;

		this._init();
	}

	//public
	/**
	 *
	 * @returns {Promise}
	 */
	toggle() {
		return super.toggle();
	}

	/**
	 * select option
	 * @param optionValue
	 */
	selectOption(optionValue) {
		this._selectOption(optionValue);
	}

	//toggle() {
	//	return this._toggle();
	//}

	// get / set
	get optionCallback() {
		return this._optionCallback
	}

	set optionCallback(fnc) {
		this._optionCallback = fnc;
	}

	// private

	_init() {
		if (this._options) {
			delegate(this._options, 'click', '.button__option', this._onClickOption.bind(this));
		}
	}

	_toggle() {
		//return super._toggle();
		return new Promise((resolve, reject) => {
			super._toggle()
				.then((status) => {
					if (status) {
						this._element.classList.add('button--open');
					}
					else {
						this._element.classList.remove('button--open');
					}

					resolve(status);

				}).catch((e) => {
					reject(e);
				});
		})
	}

	_selectOption(value) {
		if (this._options) {
			const optionElement = this._options.querySelector('[data-option="'+value+'"]');
			if (optionElement) {
				this._optionActive = optionElement;
				this._optionActive.classList.add('button__option--active');
				return true;
			}
		}
		return false;
	}

	_onClickOption(evt, element) {
		evt.preventDefault();
		evt.stopPropagation();
		if (Utils.Helper.isFunction(this._optionCallback)) {
			this._optionCallback(element);
		}
	}
}

export default ButtonSwitch;