/**
 * project: Pimcore - Salescode
 * Created by EBiermann on 14.07.2021.
 */

"use strict";

import delegate from "es6-delegate/delegate";
import {gsap} from "gsap/all";

import * as Utils from "../../utilities/Utilities";
import BaseClass from "../../classes/BaseClass";
import Init from "../../classes/Init";
import ButtonSwitch from "../Button/ButtonSwitch";


class Blog extends BaseClass.inherit(Init) {
	constructor(element, referenceElement, app) {
		super(element, referenceElement, app);

		this._filterSwitchElements = this._element.querySelectorAll('[data-filter-by]');
		this._filterSwitches = {};
		this._filter = {};

		this._urlParams = new URLSearchParams(window.location.search);
		this._isFetchingData = false;

		this._init();

	}

	_init() {
		if (this._filterSwitchElements) {
			this._processParams();
			this._filterSwitchElements.forEach((item, idx) => {
				const key = item.dataset.filterBy;
				this._filterSwitches[key] = new ButtonSwitch(item, 'button--black');
				this._filterSwitches[key].optionCallback = this._onSelectFilterOption.bind(this);
				if (this._filter[key]) {
					this._filterSwitches[key].selectOption(this._filter[key]);
				}

				delegate('click', item, this._onClickFilter.bind(this));
			});
		}

		this._attachEventsAndListeners();

	}

	_processParams() {
		if (this._urlParams.has('category')) {
			this._filter['category'] = this._urlParams.get('category');
		}

	}

	_attachEventsAndListeners() {
		delegate(this._element, 'click', '[data-filter-clear]', this._resetFilters.bind(this));
		delegate(this._element, 'click', '[data-load-more]', this._loadMore.bind(this));
	}

	_loadMore(evt, ele) {
		evt.preventDefault();

		if (this._isFetchingData) return;
		this._isFetchingData = true;

		const parentId = parseInt(ele.dataset.pid);
		const maxItems = parseInt(ele.dataset.maxItems);
		const currentItems = parseInt(ele.dataset.currentItems);
		const limit = parseInt(ele.dataset.limit);

		fetch('/' +locale+ '/blog/listing/get-items', {
			method: 'POST',
			cache: 'no-cache',
			credentials: 'same-origin',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			redirect: 'follow',
			referrerPolicy: 'no-referrer',
			body: JSON.stringify({
				parent: parentId,
				offset: currentItems,
				filterCategory: this._filter['category']
			})
		})
			.then(response => response.text())
			.then(html => {
				let te = document.createElement('div');
				te.innerHTML = html;
				te.querySelectorAll('.grid__column').forEach((column, idx) => {
					gsap.set(column, {opacity: 0});
					this.element.querySelector('.blog-listing__container').appendChild(column);
					gsap.to(column, {
						opacity: 1,
						duration: 0.8,
						delay: 0.1 * idx
					})
				});

				//this.element.querySelector('.blog-listing__container').append(html);

				ele.dataset.currentItems = currentItems + limit;
				if ((currentItems + limit) >= maxItems) {
					//remove section
					this._element.querySelector('.blog-load-more').remove();
				}

				this._isFetchingData = false;
			})
	}

	_applyFilters() {
		let queryParams = '';
		Object.keys(this._filter).forEach((key) => {
			if (queryParams != "") {
				queryParams += "&";
			}
			queryParams += key + "=" + encodeURIComponent(this._filter[key]);
		});

		//console.log(this._filter, queryParams);

		queryParams = 'filter='+encodeURIComponent(JSON.stringify(this._filter));

		//console.log(`http://google.com?${paramString.toString()}`);

		const paramString = new URLSearchParams(this._filter);
		const location = window.location;
		const url = [location.protocol, '//', location.host, location.pathname].join('');
		document.location.href = url + '?' + paramString + '';
	}

	_clearFilter(key) {
		delete this._filter[key];
	}

	_resetFilters(evt, element) {
		if (evt) evt.preventDefault();

		if (element) {

			if (element.dataset.filterClear != 'all') {
				this._clearFilter(element.dataset.filterClear);
			}
			else {
				this._filter = {};
			}
			//const url = [location.protocol, '//', location.host, location.pathname].join('');
			//document.location.href = url + '#job-search'
		}
		this._applyFilters();
	}

	_onClickFilter(evt, element) {
		if (evt) evt.preventDefault();
		Object.keys(this._filterSwitches).forEach((key) => {
			if ( this._filterSwitches[key].isActive) {
				this._filterSwitches[key].toggle();
			}
		});

		this._filterSwitches[element.dataset.filterBy].toggle();


	}

	_onSelectFilterOption(element) {
		//console.log("on select option", element, this);
		//
		const categoryElement = element.closest('[data-filter-by]');
		if (categoryElement) {
			const category = categoryElement.dataset.filterBy;
			console.log(category)
			this._filter[category] = element.dataset.option;
		}
		
		this._applyFilters();
	}
}

export default Blog;