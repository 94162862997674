/**
 * project: Pimcore - Salescode
 * Created by EBiermann on 06.08.2019.
 */


const DEBUG = devmode;
if(!DEBUG){
	if(!window.console) window.console = {};
	const methods = ["log", "debug", "warn", "info", "error"];
	for(let i=0;i<methods.length;i++){
		console[methods[i]] = function(){};
	}
}
let app = app || {};

import delegate from "es6-delegate/delegate";
import LazyLoad from "vanilla-lazyload";
import autosize from "autosize/src/autosize";
import disableScroll from "disable-scroll";

import Events from './salescode.events';
import Config from './salescode.config';
import * as Utils from './utilities/Utilities';
import * as Handlers from './handlers/Handlers';

import PageTransitionController from "./classes/PageTransitionController";
import PreloadController from "./classes/preloader/PreloadController";
import OverlayController from "./classes/overlay/OverlayController";

class Main {
	constructor() {
		this.started = false;
		this.lifecycle = {
			elements: {
				body: document.body,
				wrapper: document.body,
				triangle: document.body.querySelector('.triangle')
			},
			has: {
				triangle: false
			}
		};


		this.handlers = {};
		this.components = {};
		
		this.keys = {
			enter: 13,
			space: 32,
			tab: 9,
			esc: 27,
			left: 37,
			right: 39,
			up: 38,
			down: 50
		};

		this.Events = Events;
		this.Config = Config;
		this.Utils = Utils;
		this.Handlers = Handlers;

		this._scrollToAfterPreloadElement = null;
		this._resizeFinishedTimeout = null;

		this._init();

	}

	start() {
		if (this.started) return;
		this.started = true;

		//Utils.DispatchEvent.dispatch(Events.preload.begin, {}, window, true, false);
		//Utils.DispatchEvent.dispatch(Events.preload.finished, {}, window, true, false);
	}

	_init() {
		this._attachEventsAndListeners();

		this.handlers.swup = new PageTransitionController(this);
		this.handlers.preloadController = new PreloadController();
		this.handlers.overlayController = new OverlayController(this);
		this.handlers.mutation = new Handlers.MutationHandler(this.lifecycle.elements.wrapper);
		this.handlers.intersection = new Handlers.IntersectionHandler();
		this.handlers.resize = new Handlers.ResizeHandler();
		this.handlers.component = new Handlers.ComponentHandler(this.lifecycle.elements.wrapper, this);

		this._initComponents();

		Utils.DispatchEvent.dispatch(Events.preload.begin, {}, window, true, false, true);
	}

	_initComponents() {
		this.handlers.component.initComponents();

		this.components.lazyloadVideo = new LazyLoad({
			threshold: 100,
			elements_selector: '.lazy-video',
			class_loading: 'lazy-video--loading',
			class_loaded: 'lazy-video--loaded',
			class_error: 'lazy-video--error',
			callback_enter: (e) => {
			},
			callback_reveal: (e) => {
			},
			callback_loaded: (e) => {
				if (e.dataset.autoplay) {
					e.play();
				}
			},
			callback_error: (e) => {
				console.error("lazyload video error:",e.parentNode, e);
			},
		})

		this.components.lazyload = new LazyLoad({
			threshold: 100,
			elements_selector: '.lazy',
			class_loading: 'lazy--loading',
			class_loaded: 'lazy--loaded',
			class_error: 'lazy--error',
			callback_enter: (e) => {
				console.log("lazyload image enter:",e.parentNode, e);
				if(e.parentNode) {
					var parent = e.parentNode;
					if (parent.tagName == 'PICTURE') { parent = parent.parentNode }
					if (!Utils.Helper.isFunction(parent.classList)) return;
					parent.classList.add('lazy');
				}
			},

			callback_reveal: (e) => {
				console.log("lazyload image set:",e.parentNode, e);
				if(e.parentNode) {
					var parent = e.parentNode;
					if (parent.tagName == 'PICTURE') { parent = parent.parentNode }
					parent.classList.add('lazy--loading');
				}

				//picturefill({ reevaluate: true });
			},
			callback_loaded: (e) => {
				console.log("lazyload image load:", e.parentNode, e);
				if(e.parentNode) {
					var parent = e.parentNode;
					if (parent.tagName == 'PICTURE') { parent = parent.parentNode }
					parent.classList.add('lazy--loaded');
					parent.classList.remove('lazy--loading');

				}
				//picturefill({ reevaluate: true });
			},
			callback_error: (e) => {
				console.error("lazyload image error:",e.parentNode, e);
				if(e.parentNode) {
					var parent = e.parentNode;
					if (parent.tagName == 'PICUTRE') { parent = parent.parentNode }
					parent.classList.add('lazy--error');
				}
			},

		});

		autosize(document.querySelectorAll('textarea'));

		//self.components.mutationObserverController = new MutationObserverController(self.lifecycle.wrapper);
		//self.components.inViewController = new InViewController();

	}

	_attachEventsAndListeners() {
		Utils.Throttle.addEvent('resize', 'throttledResize');
		Utils.Throttle.addEvent('scroll', 'throttledScroll');
		
		window.addEventListener('orientationchange', this._onOrientationChange.bind(this));
		window.addEventListener('throttledResize', this._onOptimiziedResize.bind(this));
		window.addEventListener('throttledScroll', this._onOptimiziedScroll.bind(this));

		window.addEventListener(Events.preload.begin, this._onPreloadBegin.bind(this));
		window.addEventListener(Events.preload.finished, this._onPreloadFinished.bind(this));
		window.addEventListener(Events.layout.mutation.oberserved, this._onLayoutMutationOberserved.bind(this));

		window.addEventListener(Events.navigation.show.begin, this._disableScroll.bind(this));
		window.addEventListener(Events.navigation.hide.finished, this._enableScroll.bind(this));
	}

	_disableScroll(evt) {
		disableScroll.on();
	}

	_enableScroll(evt) {
		disableScroll.off();
	}

	_onPreloadBegin(evt) {
		console.log("main: onPreloadBegin", window.location.hash);

		window.scrollTo(0, 0);
		if (window.location.hash) {
			const anchorElement = document.querySelector(window.location.hash);
			if (anchorElement) {
				this._scrollToAfterPreloadElement = anchorElement;
			}
		}
		else {
			window.scrollTo(0, 0);
		}
	}

	_onPreloadFinished(evt) {
		this.lifecycle.elements.body.classList.add('layout--preloading-done');
		try {
			if (this._scrollToAfterPreloadElement !== null) {
				setTimeout(() => {
					this._scrollToAfterPreloadElement.scrollIntoView({
						behavior: 'smooth',
						block: 'start'
					});
					this._scrollToAfterPreloadElement = false;

				}, 100);
			}
		}
		catch(error) {
			console.error("scrollIntoView", error);
		}
	}

	_onLayoutMutationOberserved(evt) {
		this.lifecycle.has.triangle = this.lifecycle.elements.body.classList.contains('has--triangle');
		this.components.lazyload.update();
		this.components.lazyloadVideo.update();
	}

	_onOrientationChange(evt) {
		this._calculateDimensions(evt, false);
		this.components.lazyload.update();
		this.components.lazyloadVideo.update();
	}

	_onOptimiziedResize(evt) {
		this._calculateDimensions();
		clearTimeout(this._resizeFinishedTimeout);
		this._resizeFinishedTimeout = setTimeout(() => {
			Utils.DispatchEvent.dispatch(Events.layout.resize.did);
		}, 250);
	}

	_onOptimiziedScroll(evt) {
		let oe = evt.detail.originEvent, st = Utils.Helper.getScrollPosition('y');

		if (st > this.lifecycle.lastScrollTop) {
			this.lifecycle.scrollDirection = 'down';
		}
		else {
			this.lifecycle.scrollDirection = 'up';
		}

		Utils.DispatchEvent.dispatch(Events.layout.scroll.did, {
			scrollEvent: evt.detail.originEvent,
			scrollTop: st,
			scrollDirection: this.lifecycle.scrollDirection
		});

		this.lifecycle.lastScrollTop = st;

	}

	_calculateDimensions(evt, initialCall) {
		this.lifecycle.viewportHeight = Utils.Helper.getViewportHeight();
		this.lifecycle.viewportWidth = Utils.Helper.getViewportWidth();
		this.lifecycle.viewportRatio = app.lifecycle.viewportWidth / app.lifecycle.viewportHeight;

		Utils.DispatchEvent.dispatch(Events.layout.dimension.check, {
			initial: initialCall ? true : false
		});
	}
	
}

(() => {
	app = new Main();
	window.app = app; //new Main();

	if(document.addEventListener) {
		document.addEventListener('DOMContentLoaded', app.start.bind(app), false);
	}

	if (window.addEventListener) {
		window.addEventListener('load', app.start.bind(app));
	}
	else {
		if (window.onload) {
			var current = window.onload;

			window.onload = function (evt) {
				current(evt);
				app.start();
			}
		}
		else {
			window.onload = onLoad;
		}
	}
})();