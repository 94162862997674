/**
 * project: Pimcore - Salescode
 * Created by EBiermann on 31.10.2019.
 */


"use strict";

import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";
import Pristine from "pristinejs/src/pristine";

class Form extends BaseClass.inherit(Init) {
	constructor(element, referenceElement, app) {
		super(element, referenceElement, app);

		this._validator = new Pristine(this._element, {
			classTo: 'form__group',
			errorClass: 'form__group--has-error',
			successClass: 'form__group--has-success',
			//errotTextParent: false
			errorTextParent: 'form__group',
			errorTextTag: 'div',
			errorTextClass: 'tooltip tooltip--half'
		});

		this._init();
	}

	_init() {
		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		this._element.addEventListener('submit', this._onSubmit.bind(this));
	}

	_onSubmit(evt) {
		evt.preventDefault();
		evt.stopPropagation();
		if (this._validator.validate()) {
			this._element.submit();
		}
		else {
			console.log("error", this._validator.getErrors());
		}
	}
}

export default Form;