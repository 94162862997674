/**
 * project: Pimcore - Salescode
 * Created by EBiermann on 01.10.2019.
 */

const Config = {
	components: [
		{ classname: 'Menu', selector: '.menu', loadInPimcoreEditmode: true },
		{ classname: 'Hamburger', selector: '.hamburger', loadInPimcoreEditmode: true },
		{ classname: 'Triangle', selector: '.triangle', loadInPimcoreEditmode: true},
		{ classname: 'PageIntro', selector: '.page-intro', loadInPimcoreEditmode: true},
		{ classname: 'ScrollIndicator', selector: '.scroll-indicator', loadInPimcoreEditmode: false},
		{ classname: 'Header', selector: '.header', loadInPimcoreEditmode: false},
		{ classname: 'Tabs', selector: '.tabs', loadInPimcoreEditmode: false},
		{ classname: 'Accordion', selector: '.accordion', loadInPimcoreEditmode: false},
		//{ classname: 'TeaserBlog', selector: '.teaser--blog', loadInPimcoreEditmode: false},
		{ classname: 'TeaserJob', selector: '.teaser--job', loadInPimcoreEditmode: false},
		{ classname: 'TeaserDocument', selector: '.teaser--document', loadInPimcoreEditmode: false},
		{ classname: 'Video', selector: '.video', loadInPimcoreEditmode: false},
		{ classname: 'IntroSlider', selector: '.intro-slider', loadInPimcoreEditmode: false},
		{ classname: 'Select', selector: '.dropdown', loadInPimcoreEditmode: true},
		{ classname: 'Jobs', selector: '.jobs', loadInPimcoreEditmode: true },
		{ classname: 'Job', selector: '.job', loadInPimcoreEditmode: true },
		{ classname: 'SocialShare', selector: '.social-share', loadInPimcoreEditmode: true },
		{ classname: 'Form', selector: '.form--std', loadInPimcoreEditmode: true },
		{ classname: 'Numbers', selector: '.numbers', loadInPimcoreEditmode: false },
		{ classname: 'Totop', selector: '.to-top', loadInPimcoreEditmode: false },
		{ classname: 'Blog', selector: '.blog-listing-container', loadInPimcoreEditmode: true },
		{ classname: 'ScrollTo', selector: '.scroll-to', loadInPimcoreEditmode: false },
		{ classname: 'ExpandableList', selector: '.expandable-list', loadInPimcoreEditmode: false }

	]
}
export default Config;