/**
 * project: Pimcore - Salescode
 * Created by EBiermann on 10.10.2019.
 */

"use strict";

import {gsap} from "gsap/all";

class TabsNavIndicator {
	constructor(element) {
		this._element = element;
		this._arrowSize = null;
		this._arrowVisible = false;
		this._tweenInProgress = false;
		this._init();
	}

	//public

	/**
	 * Tween indicator to x pos with width
	 * @param x
	 * @param width
	 * @param hideArrow
	 * @returns {Promise}
	 */
	tweenTo(x, width, hideArrow) {
		return this._tweenTo(x, width, hideArrow);
	}

	/**
	 * Tween indicator to x pos with with, afterwards show arrow
	 * @param x
	 * @param width
	 * @returns {Promise}
	 */
	goto(x, width) {
		return new Promise((resolve, reject) => {
			this._tweenTo(x, width).then(() => {
				this._hideOrShowArrow(false).then(() => {
					resolve();
				}).catch((e) => {
					reject(e);
				});
			}).catch((e) => {
				reject(e);
			});
		});
	}

	/**
	 * Reset indicator to init position
	 * @returns {Promise}
	 */
	reset() {

		return new Promise((resolve, reject) => {
			this._hideOrShowArrow(true).then(() => {
				this._tweenTo(0, '100%').then(() => {
					resolve();
				}).catch((e) => {
					reject(e);
				});
			}).catch((e) => {
				reject(e);
			});
		})

		//return this._hideOrShowArrow(true).then(() => {
		//	this._tweenTo(0, '100%')
		//})

		//return this._tweenTo(0, '100%', true);
	}


	//private
	_init() {
		const dataArrowSize = this._element.dataset.arrowSize;
		this._arrowSize = dataArrowSize != 0 ? dataArrowSize : 1;
	}

	_hideOrShowArrow(hideArrow) {
		return new Promise((resolve, reject) => {
			if (this._tweenInProgress) reject("tween in progress");

			if (hideArrow && !this._arrowVisible) {
				resolve();
			}
			else if (!hideArrow && this._arrowVisible) {
				resolve();
			}
			else {
				this._tweenInProgress = true;

				gsap.to(this._element, {
					duration: 0.2,
					'--arrow-size': hideArrow ? 0 + 'rem' : this._arrowSize + 'rem',
					onComplete: () => {
						this._tweenInProgress = false;
						this._arrowVisible = hideArrow ? false : true
						resolve();
					}
				});
			}
		});
	}


	/**
	 *
	 * @param x
	 * @param width
	 * @returns {Promise}
	 * @private
	 */
	_tweenTo(x, width) {
		return new Promise((resolve, reject) => {
			if (this._tweenInProgress) {
				reject("tween in progress");
			}
			this._tweenInProgress = true;

			gsap.to(this._element, {
				duration: (this._arrowVisible ? 0.15 : 0.35),
				x: x,
				width: width,
				ease: 'expo.inOut',
				onComplete: () => {
					this._tweenInProgress = false;
					resolve();
				}
			});
		})
		/*
		return new Promise((resolve, reject) => {
			if (this._tweenInProgress) reject();

			this._hideOrShowArrow(hideArrow).then(() => {
				TweenMax.to(this._element, (this._arrowVisible ? 0.25 : 0.5), {
					//'--arrow-size': hideArrow ? 0 : this._arrowSize + 'rem',
					x: x,
					width: width,
					ease: Expo.easeInOut,
					onComplete: resolve
				});
			})
		})*/
	}

}

export default TabsNavIndicator;