/**
 * project: Pimcore - Salescode
 * Created by EBiermann on 30.10.2019.
 */


"use strict";

import BaseClass from "../../classes/BaseClass";
import Init from "../../classes/Init";

import JobApplyForm from "./JobApplyForm";
import ExpandableList from '../ExpandableList';

class Job extends BaseClass.inherit(Init) {
	constructor(element, referenceElement, app) {
		super(element, referenceElement, app);

		this._detailContainer = this._element.querySelector('.job__details');
		//this._detailContainer = this._element.querySelector('.job__details--lower');
		this._formElement = this._element.querySelector('.job__apply-form');
		this._form = new JobApplyForm(this._formElement, app);

		this._init();
	}

	//public

	//private
	_init() {
		this._attachEventsAndListeners();
		this._initExpandableLists();
	}

	_attachEventsAndListeners() {

	}

	_initExpandableLists() {
		if (this._detailContainer) {
			let listElements = this._detailContainer.querySelectorAll('UL');
			if (listElements) {
				listElements.forEach((item, idx) => {
					new ExpandableList(item, this._referenceElement, this._app);
				})
			}
		}
	}
}

export default Job;