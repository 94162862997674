/**
 * project: Pimcore - Salescode
 * Created by EBiermann on 01.10.2019.
 */


"use strict";

import Events from "../salescode.events";
import Config from "../salescode.config";
import ComponentFactory from "../factories/ComponentFactory";

class ComponentHandler {
	constructor(watchElement, app) {
		/*
		this.components = [
				{classname: 'Gallery', selector: '.area-gallery'},
				{classname: 'Slideshow', selector: '.area-slider'}
			]

			this.instances = {
			}

			this._init();
		 */

		this._app = app;
		this._watchElement = watchElement;
		this._components = {};
		this._instances = {}

		this._init();
	}

	//public

	initComponents(referenceElement) {
		return this._initComponents(referenceElement);
	}

	getInstance() {
		return this;
	}

	/**
	 *
	 * @param element
	 * @param type
	 */
	getComponentInstanceByElement(element, type) {
		return this._getComponentInstanceByElement(element, type);
	}

	register() {
		//do later
	}

	//private

	_init() {
		this._components = Config.components;
		this._components.forEach((component, idx) => {
			this._instances[component.className] = [];
		})

		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		window.addEventListener(Events.layout.mutation.oberserved, this._onLayoutMutationOberserved.bind(this));
	}

	_onLayoutMutationOberserved(evt) {
		this._initComponents();
	}

	_initComponents(referenceElement) {
		let componentElements, createdInstances = {}, componentClass;
		referenceElement = referenceElement ? referenceElement : this._watchElement;

		this._components.forEach((component, idx) => {
			if (component.loadInPimcoreEditmode === false && editmode) return; //skip component

			if (component.selector) {
				componentElements = referenceElement.querySelectorAll(component.selector);
				componentElements.forEach((element, elementIdx) => {
					if (!element.dataset.init) {
						//console.log("create new ",component.classname, this._app, componentClass);

						componentClass = ComponentFactory(component.classname);
						let io = {
							element: element,
							referenceElement: referenceElement,
							object: new componentClass(element, referenceElement, this._app)
							//object: new window[component.classname](element, referenceElement
						}


						if (!createdInstances[component.classname]) {
							createdInstances[component.classname] = [];
							this._instances[component.classname] = [];
						}

						createdInstances[component.classname].push(io);
						this._instances[component.classname].push(io);
					}
				});
			}
		});

		return createdInstances;
	}
	_getComponentInstanceByElement(element, type) {
		let foundInstance = false;
		if (this._instances[type]) {
			this._instances[type].forEach((instance, idx) => {
				if (instance.element == element) {
					try {
						foundInstance = instance.object.getInstance();
					}
					catch (e) {
						console.error(e);
					}
				}
			})
		}
		return foundInstance;
	}
}

export default ComponentHandler;