/**
 * project: Pimcore - Salescode
 * Created by EBiermann on 27.01.2022.
 */


"use strict";

import Events from "../salescode.events";
import * as Utils from "../utilities/Utilities";
import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";
import delegate from "es6-delegate/delegate";
import {gsap} from "gsap/all";

class ExpandableList extends BaseClass.inherit(Init) {
	constructor(element, referenceElement, app) {
		super(element, referenceElement, app);

		this._moreButton = null;
		this._hiddenListElements = [];
		this._listElements = this._element.querySelectorAll('LI');
		if (this._listElements && this._listElements.length > 3) {
			this._init();
		}


	}

	//public

	//private
	_init() {

		//setup
		this._listElements.forEach((item , idx) => {
			if (idx > 2) {
				gsap.set(item, { display: 'none', opacity: 0 });
				this._hiddenListElements.push(item);
			}
		})

		//create more button
		let moreButtonContainer = document.createElement('div');
		moreButtonContainer.classList.add('expandable-list__more');

		let moreButton = document.createElement('a');
		moreButton.classList.add('button', 'button--text', 'button--mint');

		let moreButtonIcon = document.createElement('div');
		moreButtonIcon.classList.add('button__icon');
		moreButtonIcon.innerHTML = '<span class="inline-svg"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><path d="M30 13H17V0h-4v13H0v4h13v13h4V17h13z"></path></svg></span>';

		let moreButtonLabel = document.createElement('div');
		moreButtonLabel.classList.add('button__label');
		moreButtonLabel.innerText = 'MORE';

		moreButton.appendChild(moreButtonIcon);
		moreButton.appendChild(moreButtonLabel);
		moreButtonContainer.appendChild(moreButton);

		moreButton.addEventListener('click', this._onClick.bind(this));

		Utils.Dom.insertAfter(moreButtonContainer, this._element);

		this._moreButton = moreButtonContainer;
	}

	_onClick(evt) {
		if (evt) evt.preventDefault();

		this._moreButton.remove();
		this._hiddenListElements.forEach((item, idx) => {
			gsap.set(item, { clearProps: 'display'} );
			//gsap.set(item, { display: 'block'} );
			gsap.to(item, { opacity: 1, duration: 0.3 });
		});
	}
}
export default ExpandableList;
