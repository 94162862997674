/**
 * project: Pimcore - Salescode
 * Created by EBiermann on 10.10.2019.
 */


"use strict";

import Flickity from 'flickity-fade';
import {gsap} from "gsap/all";

import Events from "../../salescode.events";
import * as Utils from "../../utilities/Utilities";
import BaseClass from "../../classes/BaseClass";
import Init from "../../classes/Init";

import TabsNavIndicator from "./TabsNavIndicator";
import TabItem from "./TabItem";


class Tabs extends BaseClass.inherit(Init) {
	constructor(element, referenceElement, app) {
		super(element, referenceElement, app);

		this._header = this._element.querySelector('.tabs__header');
		this._nav = this._element.querySelector('.tabs__nav');
		this._navItems = this._nav.querySelectorAll('.tabs__nav-item');
		this._content = this._element.querySelector('.tabs__content');
		this._contentWrapper = this._element.querySelector('.tabs__content-wrapper');
		this._contentItems = this._content.querySelectorAll('.tabs__item');
		this._items = [];

		this._navIndicator = new TabsNavIndicator(this._element.querySelector('.tabs__indicator-line'));
		this._navFlickity = null;

		this._contentIndex = 0;

		this._ready = false;

		this._init();
	}

	//public

	//private
	_init() {
		if (this._contentItems) {
			this._contentItems.forEach((item, idx) => {
				this._items.push(new TabItem(item));
			});

			this._switchContent(0, true);
		}

		this._navFlickity = new Flickity(this._nav, {
			contain: true,
			pageDots: false,
			prevNextButtons: false,
			//adaptiveHeight: false,
			//on: { //bind listener, as the ready event may be triggered immediately
			//	ready: this._onFlickityReady.bind(this)
			//}
		});

		this._app.handlers.intersection.attach(this._element);

		/*
		this._contentFlickity = new Flickity(this._content, {
			contain: true,
			pageDots: false,
			prevNextButtons: false,
			adaptiveHeight: true,
			fade: true
		});*/

		this._attachEventsAndListeners();
		this._initIndicator();
	}

	_attachEventsAndListeners() {
		window.addEventListener(Events.layout.resize.did, this._onDidResize.bind(this));
		this._element.addEventListener(Events.inview.in, this._onInViewIn.bind(this));
		this._navFlickity.on('staticClick', this._onStaticClick.bind(this));
		this._navFlickity.on('settle', this._onSettle.bind(this));
	}


	_switchContent(index, isInital) {
		const item = this._items[index];

		return new Promise((resolve, reject) => {
			if (item) {
				if (isInital) {
					gsap.set(this._content, {
						height: item.height,
						opacity: 1,
						onComplete: () => {
							resolve();
						}
					});
				}
				else {
					const timeline = gsap.timeline({
						onComplete: () => {
							resolve();
						}
					});
					timeline
						.to(this._content, {
							duration: 0.150,
							opacity: 0
						})
						.set(this._contentWrapper, {
							opacity: 0
						})
						.to(this._content, {
							duration: 0.150,
							height: item.height,
							ease: 'quad.inOut'
						})
						.to(this._content, {
							duration: 0.200,
							opacity: 1
						})
						.set(this._contentWrapper, {
							xPercent: 0 - (index * 100)
						})
						.to(this._contentWrapper, {
							duration: 0.300,
							opacity: 1
						});
				}
			}
			else {
				reject("no item set");
			}
		})

	}

	_initIndicator() {
		//this._moveIndicator(0);
		gsap.delayedCall(0.200, () => {
			this._moveIndicator(0).then(() => {
				this._ready = true;
			}).catch((e) => {
				console.error(e);
			});
		});
	}

	_moveIndicator(index) {
		const itemPos = this._getNavItemPositionAndWidth(index);

		return new Promise((resolve, reject) => {
			this._navIndicator.goto(itemPos.x, itemPos.width).then(() => {
				resolve();
			}).catch((e) => {
				reject(e);
				console.error(e);
			});

			//this._navIndicator.tweenTo(itemPos.x, itemPos.width, false).then(() => {
			//	resolve();
			//});
		})
	}

	_move(index) {
		return new Promise((resolve, reject) => {
			this._navIndicator.reset().then(() => {
				this._ready = false;
				this._switchContent(index, false)
					.then(() => {
						this._navFlickity.select(index);
						this._contentIndex = index;
						this._ready = true;
						resolve();
					})
					.catch((e) => {
						reject(e);
						console.error(e);
					});
			}).catch((e) => {
				console.error(e);

			});
		});
	}

	/**
	 *
	 * @param index
	 * @returns {{x: *, width: *}}
	 * @private
	 */
	_getNavItemPositionAndWidth(index) {
		const navItem = this._navItems.item(index), cs = window.getComputedStyle(navItem);
		let     x = Utils.Helper.getOffset(navItem).left - Utils.Helper.getOffset(this._header).left,
			w = Utils.Helper.getWidth(navItem, true),
			p = 0;

		if (cs.paddingLeft == '0px') {
			p = parseInt(cs.paddingRight)
			x -= p;
		}
		else if (cs.paddingRight == '0px') {
			p = parseInt(cs.paddingLeft);
		}

		w += p;

		return {
			x: x,
			width: w
		}
	}

	_onDidResize() {
		this._moveIndicator(this._navFlickity.selectedIndex).catch((e) => {
			console.error(e);
		});

		this._navFlickity.resize();
		this._navFlickity.reposition();

		//this._contentFlickity.reposition();
		//this._contentFlickity.resize();

	}

	_onStaticClick(event, pointer, cellElement, cellIndex) {
		if (!this._ready || cellIndex === undefined) return;
		this._move(cellIndex).then(() => {
		}).catch((e) => {
			console.error(e);
		});
	}

	_onSettle(index) {
		if (!this._ready || index === undefined) return;
		if (index != this._contentIndex) {
			this._move(index);
		}
		else {
			this._moveIndicator(index).catch((e) => {
				console.error(e);
			});
		}
	}

	_onInViewIn(evt) {
		const item = this._items[this._contentIndex];

		gsap.set(this._content, {
			height: item.height
		});

		this._navFlickity.resize();
		this._navFlickity.reposition();
	}

}

export default Tabs;