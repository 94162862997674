/**
 * project: Pimcore - Salescode
 * Created by EBiermann on 29.10.2019.
 */

"use strict";

import OverlayBase from "./OverlayBase";

class OverlayVideo extends OverlayBase {
	constructor(props, app) {
		super(props);

		this._type = 'video';
		this._mediaId = props;
		this._media = null;

		this._buildElement();
	}

	/**
	 *
	 * @returns {Promise}
	 */
	open() {
		return super._open().then(() => {
			setTimeout(() => {
				this._media.play();
			}, 200);

		});
	}

	close() {
		return super._close().then(() => {
			this._media.pause();
			super._destroy();
		})
	}

	destroy() {
		super._destroy();
	}

	_buildElement() {

		this._contentWrapperNode.classList.add('overlay__content--video');
		this._getContent(this._mediaId).then((html) => {
			this._contentWrapperNode.innerHTML = html;
			app.lifecycle.elements.body.appendChild(this._wrapperNode);
			this._media = this._contentWrapperNode.querySelector('.overlay__media');
			/* @todo check if media loaded */
			//super._open();
		}).catch((error) => {
			console.error(error);
		});
	}
}

export default OverlayVideo;