/**
 * project: Pimcore - Salescode
 * Created by EBiermann on 16.10.2019.
 */
"use strict";

import Events from "../../salescode.events";
import * as Utils from "../../utilities/Utilities";
import BaseClass from "../../classes/BaseClass";
import Init from "../../classes/Init";

class TeaserBase extends BaseClass.inherit(Init) {
	constructor(element) {
		super(element);

		this._content = this._element.querySelector('.teaser__content');
		this._height = 0;
	}

	//public

	//get/set
	get height() {
		return this._height;
	}

	set height(height) {
		this._height = height;
		this._element.style.height = height + 'px';
	}

	//private
	_onPreloadBegin() {

	}

	_onMutationObserved() {

		this._refreshHeight();
	}

	_onDidResize() {
	}

	_onDimensionCheck() {
		this._refreshHeight();
	}

	_refreshHeight() {
		this._height = Utils.Helper.getHeight(this._element, true);
		this._element.style.setProperty('--height', (this._height / 10) + 'rem');
	}


}

export default TeaserBase;