/**
 * project: Pimcore - Salescode
 * Created by EBiermann on 29.10.2019.
 */

"use strict";

import {gsap} from "gsap/all";

class OverlayBase {
	constructor(props) {

		this._tween = null;
		this._wrapperNode = null;
		this._contentWrapperNode = null;
		this._closeNode = null;
		this._isOpen = false;

		this._buildWrapper();
	}

	//public

	/**
	 * Open overlay
	 * @returns {Promise}
	 */
	open() {
		return this._open();
	}

	/**
	 * Closes overlay
	 * @returns {Promise}
	 */
	close() {
		return this._close();
	}

	//private
	_buildWrapper() {
		const overlayNode = document.createElement('div');
		overlayNode.classList.add('overlay');

		const overlayCloseNode = document.createElement('a');
		overlayCloseNode.classList.add('overlay__close');
		overlayCloseNode.dataset.overlayClose = true;
		overlayCloseNode.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M14.7 2.7l-1.4-1.4L8 6.6 2.7 1.3 1.3 2.7 6.6 8l-5.3 5.3 1.4 1.4L8 9.4l5.3 5.3 1.4-1.4L9.4 8z"/></svg>'

		const overlayContentNode = document.createElement('div');
		overlayContentNode.classList.add('overlay__content');

		overlayNode.appendChild(overlayCloseNode);
		overlayNode.appendChild(overlayContentNode);

		this._closeNode = overlayCloseNode;
		this._contentWrapperNode = overlayContentNode;
		this._wrapperNode = overlayNode;
	}

	/**
	 *
	 * @returns {Promise}
	 * @private
	 */
	_open() {
		return new Promise((resolve, reject) => {
			if (this._isOpen) {
				reject('is allready open');
				return;
			}

			this._wrapperNode.classList.add('overlay--ready');

			this._tween = gsap.timeline({
				onComplete: () => {
					this._isOpen = true;
					resolve();
				}
			});

			this._tween
				.fromTo(this._wrapperNode, {
					duration: 0.300,
					opacity: 0,
					scale: 0.9,
					ease: 'power4.out'
				}, {
					opacity: 1,
					scale: 1
				})
				.fromTo(this._contentWrapperNode, {
					duration: 0.300,
					opacity: 0,
					ease: 'power4.out'
				}, {
					opacity: 1
				}, 0.3)
				.fromTo(this._closeNode, {
					duration: 0.1,
					opacity: 0,
					yPercent: -100,
					ease: 'power4.out'
				},{
					opacity: 1,
					yPercent: 0
				}, 0.2);
		});
	}

	/**
	 *
	 * @returns {Promise}
	 * @private
	 */
	_close() {
		return new Promise((resolve, reject) => {
			if (!this._isOpen) {
				reject('is not open');
				return;
			}

			this._tween = gsap.timeline({
				onComplete: () => {
					this._open = false;
					this._wrapperNode.classList.remove('overlay--ready');
					resolve();
				}
			});

			this._tween
				.to(this._contentWrapperNode, 0.3, {
					opacity: 0
				})
				.to(this._closeNode, 0.1, {
					yPercent: -100
				}, 0)
				.to(this._wrapperNode, 0.3, {
					opacity: 0,
					scale: 0.9
				}, 0.3);

		})
	}

	/**
	 * get content from pimcore
	 * @param id
	 * @returns {Promise}
	 * @private
	 */
	_getContent(id) {
		return new Promise((resolve, reject) => {
			const fetchPromise = fetch('/overlay/get/'+this._type+'/'+id);
			fetchPromise
				.then((response) => {
					if (!response.ok) {
						throw Error(response.statusText);
					}

					return response.text();

				})
				.then((html) => {
					resolve(html);
				})
				.catch((error) => {
					reject(error);
				})
		})


	}

	_destroy() {
		this._tween = null;
		this._wrapperNode.remove();
	}
}

export default OverlayBase;