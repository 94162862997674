/**
 * project: Pimcore - Salescode
 * Created by EBiermann on 14.10.2019.
 */

"use strict";

import Events from "../salescode.events";
import * as Utils from "../utilities/Utilities";
import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";

class PageIntro extends BaseClass.inherit(Init) {
	constructor(element) {
		super(element);


		this._listeningElements = [];
		this._firstGridContainer = this._element.querySelector('.grid:first-child .grid__container:first-child');

		this._init();

	}

	//public

	//private
	_init() {
		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		window.addEventListener(Events.triangle.resize.did, this._onTriangleResize.bind(this));
	}

	_collectListeningElements() {
		const elements = document.querySelectorAll('[data-triangle-listener]:not([data-triangle-listener="listening"]');
		if (elements.length > 0) {
			elements.forEach((element, idx) => {
				element.dataset.triangleListener = 'listening';
				this._listeningElements.push(element);
			})
		}
	}

	_onTriangleResize(evt) {
		const dimension = evt.detail.dimension * 0.62;
		if (!this._firstGridContainer) return;
		if (this._listeningElements.length <= 0) return;
		this._firstGridContainer.style.paddingTop = (dimension + 0) + 'px';
	}

}

export default PageIntro;