/**
 * project: Pimcore - Salescode
 * Created by EBiermann on 07.10.2019.
 */


"use strict";

import Events from "../salescode.events";
import * as Utils from "../utilities/Utilities";
import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";

class Header extends BaseClass.inherit(Init) {
	constructor(element) {
		super(element);

		//this._element = element;
		this._height = 0;
		this._lastScrollTop = 0;
		this._isColored = false;

		this._init();
	}

	//public

	//private

	_init() {
		this._element.dataset.init = true;
		this._height = Utils.Helper.getHeight(this._element, true);

		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		window.addEventListener(Events.preload.finished, this._onPreloadFinished.bind(this));
		window.addEventListener(Events.layout.scroll.did, this._onOptimiziedScroll.bind(this));
		window.addEventListener(Events.navigation.show.finished, this._onNavigationShowFinsihed.bind(this));
		window.addEventListener(Events.navigation.hide.finished, this._onNavigationHideFinished.bind(this));
	}

	_onPreloadFinished(evt) {
		this._onOptimiziedScroll(null);
		this._element.classList.remove('header--collapsed');
	}

	_onNavigationShowFinsihed(evt) {
		if (this._isColored) {
			this._element.classList.remove('header--colored');
		}
	}

	_onNavigationHideFinished(evt) {
		if (this._isColored) {
			this._element.classList.add('header--colored');
		}
	}

	_onOptimiziedScroll(evt) {
		let currentScrollTop = evt ? evt.detail.scrollTop : 0, heightReference;

		if (this._lastScrollTop < currentScrollTop && currentScrollTop > (this._height * 1.5)) {
			this._element.classList.add('header--collapsed');
			Utils.DispatchEvent.dispatch(Events.header.out);
			//window.app.lifecycle.elements.body.classList.add('header--collapsed');
		}
		else if (this._lastScrollTop > currentScrollTop && ! (currentScrollTop <= this._height)) {
			this._element.classList.remove('header--collapsed');
			Utils.DispatchEvent.dispatch(Events.header.in);
			//window.app.lifecycle.elements.body.classList.remove('header--collapsed');
		}

		if (app.lifecycle.has.triangle) {
			heightReference = Utils.Helper.getHeight(app.lifecycle.elements.triangle, true) * 1.2;
		}
		else {
			heightReference = this._height * 3;
		}

		if (currentScrollTop <= heightReference) {
			this._element.classList.remove('header--colored');
			this._isColored = false;
		}
		else {
			this._element.classList.add('header--colored');
			this._isColored = true;
		}

		this._lastScrollTop = currentScrollTop;
	}
}

export default Header;
