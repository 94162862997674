/**
 * project: Pimcore - Salescode
 * Created by EBiermann on 15.10.2019.
 */


"use strict";

import {TimelineMax} from "gsap/all";

import Events from "../../salescode.events";
import * as Utils from "../../utilities/Utilities";
import BaseClass from "../../classes/BaseClass";
import Init from "../../classes/Init";

class ButtonBase extends BaseClass.inherit(Init) {
	constructor(element) {
		super(element);

		this._tweenInProgress = false;
		this._isActive = false;

		this._nonActiveClass = null;
		this._activeClass = 'button--active';
		this._timeline = null;
		this._timelineElement = null;
		this._timelineTo = null;
		this._timelineTime = 1;

	}

	//public
	/**
	 *
	 * @returns {Promise}
	 */
	toggle() {
		return this._toggle();
	}

	// get / set
	get element() {
		return this._element;
	}

	get isActive() {
		return this._isActive;
	}

	set isActive(isActive) {
		this._isActive = isActive
	}

	// private

	/**
	 *
	 * @returns {Promise}
	 * @private
	 */
	_toggle() {
		return new Promise((resolve, reject) => {
			if (this._tweenInProgress) reject('tween in progress');
			if (!this._timelineElement || !this._timelineTo) reject('no element or animation definied');

			if (!this._timeline) {
				this._timeline = new TimelineMax({
					paused: true,
					onStart: this._onStart.bind(this),
					onComplete: this._onComplete.bind(this, resolve),
					onReverseComplete: this._onReverseComplete.bind(this, resolve)
				});

				this._timeline.to(this._timelineElement, this._timelineTime, this._timelineTo);
			}

			if (this._isActive) {
				this._timeline.eventCallback('onReverseComplete', this._onReverseComplete.bind(this, resolve));
				this._timeline.reverse();
			}
			else {
				this._timeline.play();
			}

		})
	}

	_onStart() {
		this._tweenInProgress = true;
	}

	_onComplete(resolve) {
		this._tweenInProgress = false;
		this._isActive = true;
		if (this._nonActiveClass) this._element.classList.remove(this._nonActiveClass);
		this._element.classList.add(this._activeClass);
		this._element.blur();
		resolve(this._isActive);
	}

	_onReverseComplete(resolve) {
		this._timeline.kill();
		this._timeline = null;
		this._tweenInProgress = false;
		this._isActive = false;
		if (this._nonActiveClass) this._element.classList.add(this._nonActiveClass);
		this._element.classList.remove(this._activeClass);
		this._element.blur();
		resolve(this._isActive);
	}

}

export default ButtonBase;