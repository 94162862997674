/**
 * project: Pimcore - Salescode
 * Created by EBiermann on 11.12.2019.
 */
"use strict";

import BaseClass from "../../classes/BaseClass";
import Init from "../../classes/Init";
import Number from "./Number";

class Numbers extends BaseClass.inherit(Init) {
	constructor(element, referenceElement, app) {
		super(element, referenceElement, app);

		this.numbers = [];

		this._init();
	}

	_init() {
		const numberContainers = this.element.querySelectorAll('.numbers__container');
		numberContainers.forEach((item, idx) => {
			this.numbers.push(new Number(item, this._element, this._app));
		});
	}
}

export default Numbers;