/**
 * project: Pimcore - Salescode
 * Created by EBiermann on 18.10.2019.
 */

"use strict";

import {gsap} from "gsap/all";

import Events from "../salescode.events";
import * as Utils from "../utilities/Utilities";
import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";

class Menu extends BaseClass.inherit(Init) {
	constructor(element) {
		super(element);

		this._content = this._element.querySelector('.menu__content');
		this._background = this._element;
		this._navigationItemsPrimary = this._content.querySelectorAll('.navigation__item--primary');
		this._navigationItemsMeta = this._content.querySelectorAll('.navigation__item--meta');
		this._languagesItem = this._content.querySelectorAll('.languages');


		this._timelineIn = null;
		this._timelineOut = null;

		this._initialCSSWidth = Utils.Helper.getCssVariableValue(this._background, '--element-width');
		this._initialCSSDistanceToLeft = Utils.Helper.getCssVariableValue(this._background, '--element-distance-to-left');

		this._colorIn = this.element.dataset.colorIn;
		this._colorMid = this.element.dataset.colorMid;
		this._colorOut = this.element.dataset.colorOut;

		this._simpleTween = true;

		this._visible = false;

		this._init();
	}

	//public

	/**
	 *
	 * @returns {*|Promise<unknown>|Promise}
	 */
	show() {
		return this._show();
	}

	/**
	 *
	 * @returns {*|Promise<unknown>|Promise}
	 */
	hide() {
		return this._hide();
	}

	//get/set

	//private
	_init() {
		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		window.addEventListener(Events.layout.resize.did, this._onResize.bind(this));
		//window.addEventListener(Events.layout.mutation.oberserved, this._onMutationObserved.bind(this));
		//window.addEventListener(Events.preload.finished, this._onPreloadFinished.bind(this));
		//window.addEventListener('keydown', this._onKeyPress.bind(this));

		window.addEventListener(Events.navigation.do.show, this._onDoShow.bind(this));
		window.addEventListener(Events.navigation.do.hide, this._onDoHide.bind(this));
		window.addEventListener(Events.preload.xhr, this._onPreloadXHR.bind(this));
	}

	_show(onStartAction) {
		return new Promise((resolve, reject) => {

			if (this._timelineIn || this._timelineOut) {
				reject('tween in progress');
				return;
			}

			this._timelineIn = gsap.timeline({
				onStart: () => {
					this._setDimensions();
					this._element.classList.add('force-repaint');
					if (onStartAction && Utils.Helper.isFunction(onStartAction)) onStartAction();
					Utils.DispatchEvent.dispatch(Events.navigation.show.begin, {});
				},
				onComplete: () => {
					Utils.DispatchEvent.dispatch(Events.navigation.show.finished, {});
					this._element.classList.remove('force-repaint');
					this._timelineIn.kill();
					this._timelineIn = null;
					this._visible = true;

					resolve();
				}
			});

			this._timelineIn
				.set(this._element, { display: 'block'})
				.set(this._background, { opacity: 1 })
				.fromTo(this._background, 0.4, {
					backgroundColor: this._colorIn
				}, {
					backgroundColor: this._colorMid
				}, 0.2)
				.fromTo(this._background, {
					'--element-distance-to-left': this._initialCSSDistanceToLeft,
					'--element-width': this._initialCSSWidth,
				},{
					'--element-distance-to-left': '50%',
					'--element-width': '50%',
					ease: 'quad.out',
					duration: 0.45
				}, 0)
				.to(this._background, {
					duration: 0.55,
					'--element-distance-to-left': device == 'phone' ? '-200%' : '-150%',
					'--element-width': device == 'phone' ? '300%' : '250%',
					ease: 'quad.in'
				}, 0.45)

				.from(this._navigationItemsPrimary, {
					duration: 0.45,
					force3D:true,
					scale: 1.2,
					opacity: 0,
					ease: 'back.out',
					stagger: 0.1,
				}, '-=0.25')
				.from(this._languagesItem, {
					duration: 0.45,
					force3D:true,
					scale: 1.2,
					opacity: 0,
					ease: 'back.out',
					stagger: 0.1,
				}, '-=0.25')
				.from(this._navigationItemsMeta, {
					duration: 0.45,
					force3D:true,
					scale: 1.4,
					opacity: 0,
					ease: 'back.out',
					stagger: 0.1
				}, '-=0.25');


			//this._timelineIn.timeScale(0.1);
		})
	}

	_hide(onStartAction) {
		return new Promise((resolve, reject) => {

			if (this._timelineIn || this._timelineOut) {
				reject('tween in progress');
				return;
			}

			this._timelineOut = gsap.timeline({
				onStart: () => {
					this._setDimensions();
					this._element.classList.add('force-repaint');
					if (onStartAction && Utils.Helper.isFunction(onStartAction)) onStartAction();
					this._visible = true;
					Utils.DispatchEvent.dispatch(Events.navigation.hide.begin, {});

				},
				onComplete: () => {
					Utils.DispatchEvent.dispatch(Events.navigation.hide.finished, {});
					this._element.classList.remove('force-repaint');
					this._timelineOut.kill();
					this._timelineOut = null;

					resolve();

				}
			});

			this._timelineOut
				.fromTo(this._background, {
					backgroundColor: this._colorMid
				}, {
					backgroundColor: this._colorOut,
					duration: 0.3
				}, 0.25)
				.to(this._background,  {
					'--element-width': '80%',
					ease: 'quad.in',
					duration: 0.45
				}, 0)
				.to(this._background,  {
					'--element-distance-to-left': '200%',
					'--element-width': '120%',
					opacity: this._simpleTween ? 0 : 1,
					ease: 'quad.in',
					duration: 0.45
				}, 0.25)
				.set(this._element, { display: 'none'});
		})
	}

	_setDimensions() {
		gsap.set(this._background, {
			'--screen-height': Utils.Helper.getViewportHeight() + 'px'
		})
	}

	/*
	_onMutationObserved(evt) {
		this._setDimensions();
	}

	_onPreloadFinished(evt) {
		this._setDimensions();
	}

	_onKeyPress(evt) {
		if (this._visible && evt && evt.keyCode == app.keys.esc) {
			this._hide();
		}
	}*/

	_onPreloadXHR(evt) {
		Utils.DispatchEvent.dispatch(Events.navigation.hide.finished, {});

		this._element.classList.remove('force-repaint');
		if (this._timelineIn) {
			this._timelineIn.kill();
			this._timelineIn = null;
		}

		if (this._timelineOut) {
			this._timelineOut.kill();
			this._timelineOut = null;
		}
	}

	_onResize(evt) {
		this._setDimensions();
	}

	_onDoShow(evt) {
		this._show(evt.detail.action).catch((msg) => console.error(msg));
	}
	_onDoHide(evt) {
		this._hide(evt.detail.action).catch((msg) => console.error(msg));
	}
}

export default Menu;