/**
 * project: Pimcore - Salescode
 * Created by EBiermann on 09.10.2019.
 */


"use strict";
import {gsap, Draggable, ScrollToPlugin, InertiaPlugin} from "gsap/all";
//import {gsap, ScrollToPlugin, InertiaPlugin} from "gsap/all";
//import {Draggable} from "../../vendor/draggable3.min";
gsap.registerPlugin(Draggable,ScrollToPlugin,InertiaPlugin);

import * as Utils from "../utilities/Utilities";
import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";
import Events from "../salescode.events";


class ScrollIndicator extends BaseClass.inherit(Init) {
	constructor(element) {
		super(element);


		console.error("Scroll Indicator temporary disabled because of GSAP 3 Bug?")
		return;

		this._isNeeded = false;

		this._bar = this._element.querySelector('.scroll-indicator__bar');
		this._barHeight = 0
		this._dot = this._element.querySelector('.scroll-indicator__dot');
		this._dotHeight = 0
		this._label = this._element.querySelector('.scroll-indicator__label');
		this._labelHidden = false;

		this._draggable = null;
		this._dragInProgress = false;

		this._needsUpdate = false;

		//force import // gsap 2
		//let importForce = new ThrowPropsPlugin();
		//importForce = new ScrollToPlugin();

		this._init();
	}

	//public

	_getIndicatorPositionByScrollPosition() {
		let     maxScroll = document.documentElement.scrollHeight,
			currentScroll = Utils.Helper.getScrollPosition('y'),
			currentScrollInPercent = Utils.Helper.getScrollPercent('y', null, false),
			newDragPixelPosition;

		this._updateDimensions();
		this._update();

		newDragPixelPosition = Utils.Helper.calcPixelFromPercent(currentScrollInPercent, this._draggable.maxY);

		//console.log("EVENT: window scroll in %:", currentScrollInPercent, '('+currentScroll+') represents pixPosition on Dot:', newDragPixelPosition);
		return newDragPixelPosition;

	}

	_getScrollPositionByIndicatorPosition() {
		let     currentDragPositionInPercent = Utils.Helper.calcPercent(this._draggable.endY, this._draggable.maxY),
			newScrollPositionInPixel = Utils.Helper.calcPixelFromPercent(currentDragPositionInPercent, document.documentElement.scrollHeight - Utils.Helper.getViewportHeight());

		console.log("DRAG: window scroll in px: ", newScrollPositionInPixel, ' based on drag pos in %: ', currentDragPositionInPercent, ' ('+this._draggable.endY+'/'+this._draggable.maxY+')');
		return newScrollPositionInPixel;
	}

	//private
	_init() {

		this._draggable = new Draggable(this._dot, {
			type: 'y',
			bounds: this._bar,
			lockAxis: true,
			inertia: true,
			snap: {
				y: (value) => {
					let percent = Utils.Helper.calcPercent(value, this._draggable.maxY, true);

					if (percent < 10) {
						return this._draggable.minY;
					}
					else if (percent > 90) {
						return this._draggable.maxY;
					}
				},
			},
			onDragStart:() => {
				console.log("on drag start");
				//this._removeLabel();
			},
			onDrag:() => {
				console.log("on drag");
				//this._updateLine();

			},
			onThrowUpdate: () => {
				console.log("on throw update");
				//this._updateLine();
			},
			onThrowComplete: () => {

				let y = this._getScrollPositionByIndicatorPosition();
				this._updateLine();

				console.log("on throw complete", y);

				//TweenMax.set(window, {
				//	scrollTo: {
				//		y: y
				//	}
				//}).delay(0.100);


				this._dragInProgress = true
				gsap.to(window,{
					duration: 1,
					scrollTo: {
						y: y
					},
					ease: 'expo.inOut',
					onStart:() => {
						this._draggable.disable();
					},
					onComplete: () => {
						this._draggable.enable();
						gsap.delayedCall(0.010, () => {
							this._dragInProgress = false;
						});
					}
				});
			}
		});

		//this._draggable = Draggable.get(this._dot);

		window.test = this._draggable;

		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		window.addEventListener(Events.layout.mutation.oberserved, this._onMutationOberserved.bind(this));
		window.addEventListener(Events.general.scroll, this._onThrottledScroll.bind(this));
		window.addEventListener(Events.general.resize, this._onThrottledResize.bind(this));
	}

	_onMutationOberserved(evt) {
		let scrollHeight = document.documentElement.scrollHeight, vpHeight = Utils.Helper.getViewportHeight();
		//show only if scrollHeight is greater then vpHeight + half vpHeight
		this._isNeeded = scrollHeight > (vpHeight * 1.5);
		if (this._isNeeded) {
			this._updateDimensions();
			//this._update();
			this._element.classList.remove('scroll-indicator--hidden');
		}
	}

	_onThrottledScroll(evt) {
		let y;

		if (!this._dragInProgress) {
			this._removeLabel();
			y = this._getIndicatorPositionByScrollPosition();
			gsap.set(this._dot, {
				y: y
			});
			this._updateLine(y);
		}
	}

	_onThrottledResize(evt) {
		this._updateDimensions();
		this._update();
		this._updateLine();
	}

	_removeLabel() {

		if (!this._labelHidden) {
			this._element.classList.add('scroll-indicator--label-hidden');

			let p = this._updateLine(null, true);

			gsap.to(this._bar, 0.3, {
				'--line-stop': p.stop + 'px',
				'--line-stop-end': p.stopEnd + 'px',
				onComplete: () => {
					this._labelHidden = true;
					this._updateLine();
				}
			})
			this._updateLine();
		}
	}

	_updateDimensions() {
		//this._barHeight = Utils.Helper.getHeight(this._bar, true);
		this._dotHeight = Utils.Helper.getHeight(this._dot, true);
	}

	_update() {
		//console.log("update", this._draggable);
		this._draggable.disable();
		this._draggable.update(true);
		this._draggable.enable();
	}

	/**
	 *
	 * @param y
	 * @param onlyReturnValues
	 * @returns {{stop: *, stopEnd: *}}
	 * @private
	 */
	_updateLine(y, onlyReturnValues) {
		let lineStop, lineStopEnd, distanceToPointInPixel = 4;

		if (!this._labelHidden && onlyReturnValues != true) return;

		y = y ? y : this._draggable.endY;
		if (y === undefined) y = 100;

		lineStop = y - distanceToPointInPixel;
		lineStopEnd = y + this._dotHeight + distanceToPointInPixel;

		if (!onlyReturnValues) {
			this._bar.style.setProperty('--line-stop', lineStop + 'px');
			this._bar.style.setProperty('--line-stop-end', lineStopEnd + 'px');
		}

		return {
			stop: lineStop,
			stopEnd: lineStopEnd
		}
	}


}

export default ScrollIndicator;